<template>
  <v-dialog :value="value" persistent max-width="650">
    <v-card>
      <v-toolbar dark flat color="#1C3144">
        <v-btn icon @click="$emit('update:value', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Errors</v-toolbar-title>
      </v-toolbar>
      <v-container class="ma-0 pa-6" style="max-height:300px; overflow:auto;">
        <v-list class="grey lighten-5" v-if="typeof errors == 'object'">
          <v-list-item v-for="(error, index) in errors" :key="index">
            <span class="red--text">{{error}}</span>
          </v-list-item>
        </v-list>
        <v-list-item v-else>
          <span class="red--text">{{errors}}</span>
        </v-list-item>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ErrorDisplay',

  props: ['value', 'errors']
}
</script>
