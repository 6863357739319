var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    { attrs: { value: _vm.value, persistent: "", "max-width": "650" } },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            { attrs: { dark: "", flat: "", color: "#1C3144" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("update:value", false)
                    }
                  }
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
              _c("v-toolbar-title", [_vm._v("Errors")])
            ],
            1
          ),
          _c(
            "v-container",
            {
              staticClass: "ma-0 pa-6",
              staticStyle: { "max-height": "300px", overflow: "auto" }
            },
            [
              typeof _vm.errors == "object"
                ? _c(
                    "v-list",
                    { staticClass: "grey lighten-5" },
                    _vm._l(_vm.errors, function(error, index) {
                      return _c("v-list-item", { key: index }, [
                        _c("span", { staticClass: "red--text" }, [
                          _vm._v(_vm._s(error))
                        ])
                      ])
                    }),
                    1
                  )
                : _c("v-list-item", [
                    _c("span", { staticClass: "red--text" }, [
                      _vm._v(_vm._s(_vm.errors))
                    ])
                  ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }